import React from 'react'
import { Title } from '../../components/UI/Title'

export const CEOMessageContent = () => {
  return (
    <div>
      <Title tw="text-center">CEO Message</Title>

      <article tw="mt-10 space-y-6">
        <p>
          Wyoming Banking Division has led the nation in creating the regulatory 
          framework that established the Special Purpose Depository Institutions 
          (SPDI) regime, being a non-lending banking license for digital asset 
          connectivity to traditional banking systems. Commercium is delighted 
          to be one of the first Wyoming SPDIs to take advantage of digital 
          banking and qualified custody of digital assets and digital 
          securities, under Wyoming's customer friendly bailment legislation. 
        </p>
        <p>
          We are delighted to operate in the great state of Wyoming, a state that 
          is leading the nation in innovation, a business-friendly operating 
          environment, and a deep talent pool of local residents to help drive 
          our business forward.
        </p>
        <p>
          As the CEO of this organization, I am proud to lead a team of 
          professionals that are dedicated to providing you with the best 
          possible financial services experience. Our focus on technology, 
          regulation and compliance are the pillars of our organization and 
          allow us to serve you with confidence.
        </p>
        <p>
          At our institution, we understand that you have unique financial needs, 
          and we are committed to providing you with tailored solutions to meet 
          those needs. We are dedicated to investing in the latest technology to 
          ensure that you have access to your accounts at any time, from anywhere. 
          Our state-of-the-art security measures are designed to protect your 
          personal and financial information.
        </p>
        <p>
          Regulation and compliance are at the heart of what we do. Our commitment 
          to upholding the highest standards of regulation and compliance ensures 
          that our institution is a trusted financial partner for you. We pride 
          ourselves on being transparent and accountable in everything we do.
        </p>
        <p>
          We believe that building long-lasting relationships with our clients is 
          critical to our success. We strive to provide personalized service that is 
          second to none, and we are always here to answer any questions you may have.
        </p>
        <p>
          We invite you to explore our website to learn more about the products and 
          services we offer and to get in touch with us to start your banking journey 
          with us.
        </p>
        <p>
          Thank you for considering our institution as your financial partner.
        </p>
      </article>


      <article tw="mt-10 space-y-6">
        <p tw="font-semibold mt-6 text-xl">Corey A. Reason<br />
        <span tw="font-semibold text-xl text-blueGray-500 text-sm">
          Chief Executive Officer
        </span>
        </p>
      </article>
    </div>
  )
}
