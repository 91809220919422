import React from 'react'
import { Helmet } from 'react-helmet'
import { Footer } from '../components/Layout/Footer'
import { Container } from '../components/UI/Container'
import { CEOMessageContent } from '../views/ceo-message/CEOMessage'
import { Navbar } from '../components/Layout/Navbar'

const CEOMessagePage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CEO Message — Commercium Bank, Inc</title>
      </Helmet>

      <Navbar />

      <Container tw="pt-32 pb-20">
        <CEOMessageContent />
      </Container>

      <Footer />
    </div>
  )
}

export default CEOMessagePage
